import { FlashcardDto } from "../../../types";
import { isAfter, subDays } from "date-fns";

const newerThan = (days: number) => (flashcard: FlashcardDto) => {
  return isAfter(flashcard.createTime, subDays(new Date(), days));
};

const newerThan45 = newerThan(45);

export const defaultLiveMode = "Ring" as const;

type BelongsToCollection = (
  collectionName: string,
  flashcardId: string
) => boolean;

export const liveSettings: Record<
  string,
  (flashcard: FlashcardDto, belongsToCollection: BelongsToCollection) => boolean
> = {
  [defaultLiveMode]: (flashcard) => (flashcard.ring || 0) > 3,
  Jitowe: (f) => f.context.includes("JENG"),
  NoweJitowe: (f) => f.context.includes("JENG") && newerThan45(f),
  Disco: (f) => f.context.includes("DISCO"),
  Gadaj: (f) => f.context.includes("GAD"),
  Phrasale: (f) => f.context.includes("PHRAS"),
  Net: (f) => f.context.includes("@NET"),
  Warhammer: (f) => f.context.includes("WAR"),
  Short: (f) => f.engText.length <= 4,
  FI5: (f) => f.context.includes("FI5"),
  FI6: (f) => f.context.includes("FI6"),
  Workee: (f, belongs) => belongs("workee", f._id),
  Chatty: (f, belongs) => belongs("chatty", f._id),
  Master: (f, belongs) => belongs("master", f._id),
} as const;
